.section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 160px;
  padding: 15px 20px;
  box-shadow: 0px -2px 19px -18px rgba(0, 0, 0, 0.45) inset;
  border-radius: 4px;
}

.section:hover {
  box-shadow: 0px 8px 19px -18px rgba(0, 0, 0, 0.45) inset,
    0px -8px 19px -18px rgba(0, 0, 0, 0.45) inset;
}

.text-div {
  flex-basis: 80%;
}

.text-div h3 {
  font-size: 20px;
}

.text-div h5 {
  font-size: 0.83em;
}

.text-div p {
  font-size: 14px;
}

.img {
  width: 160px;
  height: auto;
}

@media screen and (max-width: 600px) {
  .text-div {
    padding-right: 20px;
  }

  .text-div h3 {
    font-size: 16px;
  }

  .text-div h5 {
    font-size: 0.7em;
  }

  .text-div p {
    font-size: 14px;
  }
  .section {
    height: auto;
  }
}
