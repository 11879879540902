.container {
  display: flex;
  justify-content: space-evenly;
  /* padding: 5px 0px; */
  background-color: white;
  /* color: blue; */
}

.container Button {
  flex-grow: 3;
}

.container div {
  flex-grow: 1;
}

@media screen and (max-width: 600px) {
  .container {
    flex-direction: column;
  }
}
