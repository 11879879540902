.book {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 30%;
}

.book a img {
  width: 125px;
  height: 187.5px;
  border: 0.1px solid black;
}

@media screen and (max-width: 600px) {
  .book {
    width: auto;
  }
}
/* 
.book h3 {
  font-size: 12pt;
  color: rgb(33, 57, 57);
} */
/* 
.book p {
  font-size: 10pt;
  height: 50px;
  overflow-y: scroll;
} */
