.intro-section {
  display: flex;
  background-color: rgba(206, 255, 252, 0.847);
  /* border-bottom: 0.2px solid rgb(68, 2, 2);
  border-top: 0.2px solid rgb(68, 2, 2); */
  margin: 50px 30px;
  box-shadow: 0 10px 40px rgb(0 0 0 / 0.2);
  font-family: "Source Serif Pro", serif;
  font-weight: 300;
  font-size: 20px;
}

.intro-section img {
  width: 30%;
  max-height: 250px;
  max-width: fit-content;
}

.intro-section div {
  background-color: #9d97de;
  color: aliceblue;
  display: flex;
  align-items: center;
}

.intro-section div p {
  padding: 0px 20px;
}

@media screen and (max-width: 768px) {
  .intro-section {
    font-size: 16px;
  }
}

@media screen and (max-width: 600px) {
  .intro-section {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .intro-section div {
    background-color: transparent;
    color: black;
    font-size: 14px;
  }
  .intro-section img {
    border-radius: 50%;
    width: 125px;
    padding: 10px;
  }
}
