.nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.nav li {
  margin: 0;
  margin-left: 1rem;
}

.nav a {
  text-decoration: none;
  color: white;
}

.nav a:hover,
.nav a:active {
  color: #f3cafb;
}

/* Icon Bounce */
.hvr-icon-bounce {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.hvr-icon-bounce .hvr-icon {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-icon-bounce:hover .hvr-icon,
.hvr-icon-bounce:focus .hvr-icon,
.hvr-icon-bounce:active .hvr-icon {
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
  -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
  transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
}
