.header {
  display: flex;
  flex-direction: column;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #7571a4;
  color: white;
  display: flex;
  /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25); */
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
  z-index: 10;
  /* border-bottom: 1px solid red; */
  font-family: "Gloock";
}
