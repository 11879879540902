.card {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 10px;
  text-align: center;
}

.card img {
  max-width: 90%;
  height: auto;
}
