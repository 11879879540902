.contribution {
  display: flex;
  flex-direction: row;
  margin: 15px 0;
  padding: 10px 15%;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 12px 2px;
  align-items: center;
}

.contribution:hover {
  background-color: rgba(76, 165, 203, 0.15);
}

.contribution img {
  width: auto;
  height: 150px;
  margin-bottom: 20px;
}

.contribution div {
  flex-direction: column;
  align-content: stretch;
  padding: 0;
  margin: 0 0 0 20px;
  align-self: flex-start;
  width: 100%;
}

.contribution div h2 {
  font-family: "Source Serif Pro", serif;
  width: 100%;
  text-align: left;
  font-size: 20px;
  color: black;
  border: none;
  margin: 0;
  padding: 0;
}

.contribution div h3 {
  font-family: "Source Serif Pro", serif;
  font-weight: 100;
  width: 100%;
  text-align: left;
  font-size: 16px;
  color: black;
  border: none;
  margin: 18px 0px 0px 0px;
  padding: 0;
}

.contribution div p {
  text-align: justify;
  width: 100%;
  font-size: 14px;
  font-family: "Source Serif Pro", serif;
  font-weight: 100;
}

.contribution div p span {
  color: blue;
}

.contribution div p span:hover {
  text-decoration: underline;
}
