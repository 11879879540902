.radio-show {
  box-shadow: 0px 0px 0px 0.1px #c98bff;
  transition: box-shadow 0.2s ease-in;
  margin: 0.5em;
  height: 220px;
  background-color: white;
}

.radio-show:hover {
  box-shadow: 0px 0px 0px 2px rgba(124, 0, 233, 0.4);
}

.h2 {
  text-align: center;
  padding-top: 6px;
  font-family: "Source Serif Pro", serif;
  font-weight: 100;
  font-size: 14px;
  border: none;
  margin: 0;
  color: black;
  width: 100%;
}

@media screen and (max-width: 600px) {
  .h2 {
    font-size: 16px;
  }

  .radio-show {
    height: 230px;
  }
}
