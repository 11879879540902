.button {
  color: black;
  font: inherit;
  border: none;
  background: none;
  padding: 1rem 2rem;
  cursor: pointer;
  font-size: 1.4rem;
  font-family: "Source Serif Pro", serif;
}

.button:hover,
.button:active {
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
}

.button:focus {
  outline: none;
}
