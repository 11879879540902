.h1 {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60%;
  border-bottom: 0.1px solid black;
  font-size: 50px;
  font-family: "Source Serif Pro", serif;
  font-weight: 400;
  padding: 0px 0px;
  margin: 20px 20% 22px 20%;
}

.center {
  margin: 0px 15%;
}

.pieces {
  height: 400px;
  overflow-y: scroll;
  border-radius: 8px;
  background-color: rgba(215, 248, 255, 0.55);
  margin-bottom: 30px;
  box-shadow: 0px 10px 20px -18px rgba(0, 0, 0, 0.45) inset,
    0px -10px 20px -18px rgba(0, 0, 0, 0.45) inset;
}

h2 {
  text-align: center;
  width: 25%;
  color: #203536;
  border-bottom: 0.1px solid black;
  font-size: 30px;
  font-family: "Gloock";
  font-weight: 100;
  padding: 0px 0px;
  margin: 10px 37.5%;
}

@media screen and (max-width: 600px) {
  .center {
    margin: 0px 4%;
  }
  .h1 {
    width: 60%;
    margin: 20px 20% 22px 20%;
  }
}
