.h1 {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 60%;
  border-bottom: 0.1px solid black;
  font-size: 50px;
  font-family: "Source Serif Pro", serif;
  font-weight: 400;
  padding: 0px 0px;
  margin: 0px 20% 22px 20%;
}

/* @media screen and (max-width: 600px) {
  .section div {
    flex-direction: column;
    align-self: center;
  }
}

.section h2 {
  width: 25%;
  color: #203536;
  border-bottom: 0.1px solid black;
  font-size: 30px;
  font-family: "Gloock";
  font-weight: 100;
  padding: 0px 0px;
  margin: 10px 37.5%;
} */
