.section {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 40px 0px;
}

.section h1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
  border-bottom: 0.1px solid black;
  font-size: 50px;
  font-family: "Source Serif Pro", serif;
  font-weight: 500;
  padding: 0px 0px;
  margin: 0px 20%;
}

.section div {
  margin: 20px 20px;
  display: flex;
}

.section h2 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%;
  color: #203536;
  border-bottom: 0.1px solid black;
  font-size: 30px;
  font-family: "Source Serif Pro", serif;
  padding: 0px 0px;
  margin: 10px 37.5%;
}

@media screen and (max-width: 600px) {
  .section div {
    /* flex-direction: column; */
    flex-wrap: wrap;
    align-self: center;
    justify-content: center;
  }
  .section h2 {
    width: 60%;
    margin: 10px 20%;
  }
}
