.section {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 40px 0px 20px 0px;
}

.section h1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
  border-bottom: 0.1px solid black;
  font-size: 50px;
  font-family: "Source Serif Pro", serif;
  font-weight: 400;
  padding: 0px 0px;
  margin: 0px 20%;
}

.section div {
  display: flex;
  border-bottom: 0.2px solid rgb(68, 2, 2);
  border-top: 0.2px solid rgb(68, 2, 2);
  margin: 20px 0px;
  padding: 0px 50px 0px 50px;
  box-shadow: 0 10px 40px rgb(0 0 0 / 0.2);
  font-family: "Playfair Display";
  font-weight: 500;
  font-size: 20px;
}

@media screen and (max-width: 600px) {
  .section h1 {
    width: 60%;
    margin: 10px 20%;
  }
}
