.top-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid;
  font-family: "Source Serif Pro", serif;
  font-weight: 300;
}

nav {
  padding-right: 1.5rem;
}

a.nostyle:link {
  text-decoration: inherit;
  color: inherit;
}

a.nostyle:visited {
  text-decoration: inherit;
  color: inherit;
}

h1 {
  padding-left: 3rem;
  font-weight: 400;
}
